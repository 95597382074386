/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createStakeholder = /* GraphQL */ `mutation CreateStakeholder(
  $input: CreateStakeholderInput!
  $condition: ModelStakeholderConditionInput
) {
  createStakeholder(input: $input, condition: $condition) {
    id
    name
    category
    Reports {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStakeholderMutationVariables,
  APITypes.CreateStakeholderMutation
>;
export const updateStakeholder = /* GraphQL */ `mutation UpdateStakeholder(
  $input: UpdateStakeholderInput!
  $condition: ModelStakeholderConditionInput
) {
  updateStakeholder(input: $input, condition: $condition) {
    id
    name
    category
    Reports {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStakeholderMutationVariables,
  APITypes.UpdateStakeholderMutation
>;
export const deleteStakeholder = /* GraphQL */ `mutation DeleteStakeholder(
  $input: DeleteStakeholderInput!
  $condition: ModelStakeholderConditionInput
) {
  deleteStakeholder(input: $input, condition: $condition) {
    id
    name
    category
    Reports {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStakeholderMutationVariables,
  APITypes.DeleteStakeholderMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    username
    email
    sprache
    group
    privacyPolicyAccepted
    Partner {
      id
      name
      Mandantens {
        items {
          id
          name
          partnerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Mandantens {
      id
      name
      partnerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      items {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userPartnerId
    userKlientId
    userMandantensId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    username
    email
    sprache
    group
    privacyPolicyAccepted
    Partner {
      id
      name
      Mandantens {
        items {
          id
          name
          partnerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Mandantens {
      id
      name
      partnerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      items {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userPartnerId
    userKlientId
    userMandantensId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    username
    email
    sprache
    group
    privacyPolicyAccepted
    Partner {
      id
      name
      Mandantens {
        items {
          id
          name
          partnerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Mandantens {
      id
      name
      partnerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      items {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userPartnerId
    userKlientId
    userMandantensId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createPartner = /* GraphQL */ `mutation CreatePartner(
  $input: CreatePartnerInput!
  $condition: ModelPartnerConditionInput
) {
  createPartner(input: $input, condition: $condition) {
    id
    name
    Mandantens {
      items {
        id
        name
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePartnerMutationVariables,
  APITypes.CreatePartnerMutation
>;
export const updatePartner = /* GraphQL */ `mutation UpdatePartner(
  $input: UpdatePartnerInput!
  $condition: ModelPartnerConditionInput
) {
  updatePartner(input: $input, condition: $condition) {
    id
    name
    Mandantens {
      items {
        id
        name
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartnerMutationVariables,
  APITypes.UpdatePartnerMutation
>;
export const deletePartner = /* GraphQL */ `mutation DeletePartner(
  $input: DeletePartnerInput!
  $condition: ModelPartnerConditionInput
) {
  deletePartner(input: $input, condition: $condition) {
    id
    name
    Mandantens {
      items {
        id
        name
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePartnerMutationVariables,
  APITypes.DeletePartnerMutation
>;
export const createMandanten = /* GraphQL */ `mutation CreateMandanten(
  $input: CreateMandantenInput!
  $condition: ModelMandantenConditionInput
) {
  createMandanten(input: $input, condition: $condition) {
    id
    name
    partnerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMandantenMutationVariables,
  APITypes.CreateMandantenMutation
>;
export const updateMandanten = /* GraphQL */ `mutation UpdateMandanten(
  $input: UpdateMandantenInput!
  $condition: ModelMandantenConditionInput
) {
  updateMandanten(input: $input, condition: $condition) {
    id
    name
    partnerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMandantenMutationVariables,
  APITypes.UpdateMandantenMutation
>;
export const deleteMandanten = /* GraphQL */ `mutation DeleteMandanten(
  $input: DeleteMandantenInput!
  $condition: ModelMandantenConditionInput
) {
  deleteMandanten(input: $input, condition: $condition) {
    id
    name
    partnerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMandantenMutationVariables,
  APITypes.DeleteMandantenMutation
>;
export const createKlient = /* GraphQL */ `mutation CreateKlient(
  $input: CreateKlientInput!
  $condition: ModelKlientConditionInput
) {
  createKlient(input: $input, condition: $condition) {
    id
    name
    Report {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKlientMutationVariables,
  APITypes.CreateKlientMutation
>;
export const updateKlient = /* GraphQL */ `mutation UpdateKlient(
  $input: UpdateKlientInput!
  $condition: ModelKlientConditionInput
) {
  updateKlient(input: $input, condition: $condition) {
    id
    name
    Report {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKlientMutationVariables,
  APITypes.UpdateKlientMutation
>;
export const deleteKlient = /* GraphQL */ `mutation DeleteKlient(
  $input: DeleteKlientInput!
  $condition: ModelKlientConditionInput
) {
  deleteKlient(input: $input, condition: $condition) {
    id
    name
    Report {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKlientMutationVariables,
  APITypes.DeleteKlientMutation
>;
export const createFragebogen = /* GraphQL */ `mutation CreateFragebogen(
  $input: CreateFragebogenInput!
  $condition: ModelFragebogenConditionInput
) {
  createFragebogen(input: $input, condition: $condition) {
    id
    name
    fragen {
      items {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFragebogenMutationVariables,
  APITypes.CreateFragebogenMutation
>;
export const updateFragebogen = /* GraphQL */ `mutation UpdateFragebogen(
  $input: UpdateFragebogenInput!
  $condition: ModelFragebogenConditionInput
) {
  updateFragebogen(input: $input, condition: $condition) {
    id
    name
    fragen {
      items {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFragebogenMutationVariables,
  APITypes.UpdateFragebogenMutation
>;
export const deleteFragebogen = /* GraphQL */ `mutation DeleteFragebogen(
  $input: DeleteFragebogenInput!
  $condition: ModelFragebogenConditionInput
) {
  deleteFragebogen(input: $input, condition: $condition) {
    id
    name
    fragen {
      items {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFragebogenMutationVariables,
  APITypes.DeleteFragebogenMutation
>;
export const createFrage = /* GraphQL */ `mutation CreateFrage(
  $input: CreateFrageInput!
  $condition: ModelFrageConditionInput
) {
  createFrage(input: $input, condition: $condition) {
    id
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    frage
    skill {
      id
      name
      de
      en
      it
      fr
      kompetenz {
        id
        name
        de
        en
        it
        fr
        skills {
          items {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      kompetenzSkillsId
      __typename
    }
    translation {
      id
      de
      en
      it
      fr
      role
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      translationFrageId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenFragenId
    frageSkillId
    frageTranslationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFrageMutationVariables,
  APITypes.CreateFrageMutation
>;
export const updateFrage = /* GraphQL */ `mutation UpdateFrage(
  $input: UpdateFrageInput!
  $condition: ModelFrageConditionInput
) {
  updateFrage(input: $input, condition: $condition) {
    id
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    frage
    skill {
      id
      name
      de
      en
      it
      fr
      kompetenz {
        id
        name
        de
        en
        it
        fr
        skills {
          items {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      kompetenzSkillsId
      __typename
    }
    translation {
      id
      de
      en
      it
      fr
      role
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      translationFrageId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenFragenId
    frageSkillId
    frageTranslationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFrageMutationVariables,
  APITypes.UpdateFrageMutation
>;
export const deleteFrage = /* GraphQL */ `mutation DeleteFrage(
  $input: DeleteFrageInput!
  $condition: ModelFrageConditionInput
) {
  deleteFrage(input: $input, condition: $condition) {
    id
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    frage
    skill {
      id
      name
      de
      en
      it
      fr
      kompetenz {
        id
        name
        de
        en
        it
        fr
        skills {
          items {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      kompetenzSkillsId
      __typename
    }
    translation {
      id
      de
      en
      it
      fr
      role
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      translationFrageId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenFragenId
    frageSkillId
    frageTranslationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFrageMutationVariables,
  APITypes.DeleteFrageMutation
>;
export const createKompetenz = /* GraphQL */ `mutation CreateKompetenz(
  $input: CreateKompetenzInput!
  $condition: ModelKompetenzConditionInput
) {
  createKompetenz(input: $input, condition: $condition) {
    id
    name
    de
    en
    it
    fr
    skills {
      items {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKompetenzMutationVariables,
  APITypes.CreateKompetenzMutation
>;
export const updateKompetenz = /* GraphQL */ `mutation UpdateKompetenz(
  $input: UpdateKompetenzInput!
  $condition: ModelKompetenzConditionInput
) {
  updateKompetenz(input: $input, condition: $condition) {
    id
    name
    de
    en
    it
    fr
    skills {
      items {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKompetenzMutationVariables,
  APITypes.UpdateKompetenzMutation
>;
export const deleteKompetenz = /* GraphQL */ `mutation DeleteKompetenz(
  $input: DeleteKompetenzInput!
  $condition: ModelKompetenzConditionInput
) {
  deleteKompetenz(input: $input, condition: $condition) {
    id
    name
    de
    en
    it
    fr
    skills {
      items {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKompetenzMutationVariables,
  APITypes.DeleteKompetenzMutation
>;
export const createSkill = /* GraphQL */ `mutation CreateSkill(
  $input: CreateSkillInput!
  $condition: ModelSkillConditionInput
) {
  createSkill(input: $input, condition: $condition) {
    id
    name
    de
    en
    it
    fr
    kompetenz {
      id
      name
      de
      en
      it
      fr
      skills {
        items {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    kompetenzSkillsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillMutationVariables,
  APITypes.CreateSkillMutation
>;
export const updateSkill = /* GraphQL */ `mutation UpdateSkill(
  $input: UpdateSkillInput!
  $condition: ModelSkillConditionInput
) {
  updateSkill(input: $input, condition: $condition) {
    id
    name
    de
    en
    it
    fr
    kompetenz {
      id
      name
      de
      en
      it
      fr
      skills {
        items {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    kompetenzSkillsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillMutationVariables,
  APITypes.UpdateSkillMutation
>;
export const deleteSkill = /* GraphQL */ `mutation DeleteSkill(
  $input: DeleteSkillInput!
  $condition: ModelSkillConditionInput
) {
  deleteSkill(input: $input, condition: $condition) {
    id
    name
    de
    en
    it
    fr
    kompetenz {
      id
      name
      de
      en
      it
      fr
      skills {
        items {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    kompetenzSkillsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillMutationVariables,
  APITypes.DeleteSkillMutation
>;
export const createTranslation = /* GraphQL */ `mutation CreateTranslation(
  $input: CreateTranslationInput!
  $condition: ModelTranslationConditionInput
) {
  createTranslation(input: $input, condition: $condition) {
    id
    de
    en
    it
    fr
    role
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    translationFrageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTranslationMutationVariables,
  APITypes.CreateTranslationMutation
>;
export const updateTranslation = /* GraphQL */ `mutation UpdateTranslation(
  $input: UpdateTranslationInput!
  $condition: ModelTranslationConditionInput
) {
  updateTranslation(input: $input, condition: $condition) {
    id
    de
    en
    it
    fr
    role
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    translationFrageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTranslationMutationVariables,
  APITypes.UpdateTranslationMutation
>;
export const deleteTranslation = /* GraphQL */ `mutation DeleteTranslation(
  $input: DeleteTranslationInput!
  $condition: ModelTranslationConditionInput
) {
  deleteTranslation(input: $input, condition: $condition) {
    id
    de
    en
    it
    fr
    role
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    translationFrageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTranslationMutationVariables,
  APITypes.DeleteTranslationMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    name
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    stakeholderKategorie
    selfassesment
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      id
      name
      category
      Reports {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    stakeholderReportsId
    klientReportId
    reportFragebogenId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    name
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    stakeholderKategorie
    selfassesment
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      id
      name
      category
      Reports {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    stakeholderReportsId
    klientReportId
    reportFragebogenId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    name
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    stakeholderKategorie
    selfassesment
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      id
      name
      category
      Reports {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    stakeholderReportsId
    klientReportId
    reportFragebogenId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createFragebogenErgebnis = /* GraphQL */ `mutation CreateFragebogenErgebnis(
  $input: CreateFragebogenErgebnisInput!
  $condition: ModelFragebogenErgebnisConditionInput
) {
  createFragebogenErgebnis(input: $input, condition: $condition) {
    id
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    report {
      id
      name
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      stakeholderKategorie
      selfassesment
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stakeholderReportsId
      klientReportId
      reportFragebogenId
      __typename
    }
    reportId
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenErgebnisFrageId
    fragebogenErgebnisReportId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFragebogenErgebnisMutationVariables,
  APITypes.CreateFragebogenErgebnisMutation
>;
export const updateFragebogenErgebnis = /* GraphQL */ `mutation UpdateFragebogenErgebnis(
  $input: UpdateFragebogenErgebnisInput!
  $condition: ModelFragebogenErgebnisConditionInput
) {
  updateFragebogenErgebnis(input: $input, condition: $condition) {
    id
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    report {
      id
      name
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      stakeholderKategorie
      selfassesment
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stakeholderReportsId
      klientReportId
      reportFragebogenId
      __typename
    }
    reportId
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenErgebnisFrageId
    fragebogenErgebnisReportId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFragebogenErgebnisMutationVariables,
  APITypes.UpdateFragebogenErgebnisMutation
>;
export const deleteFragebogenErgebnis = /* GraphQL */ `mutation DeleteFragebogenErgebnis(
  $input: DeleteFragebogenErgebnisInput!
  $condition: ModelFragebogenErgebnisConditionInput
) {
  deleteFragebogenErgebnis(input: $input, condition: $condition) {
    id
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    report {
      id
      name
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      stakeholderKategorie
      selfassesment
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stakeholderReportsId
      klientReportId
      reportFragebogenId
      __typename
    }
    reportId
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenErgebnisFrageId
    fragebogenErgebnisReportId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFragebogenErgebnisMutationVariables,
  APITypes.DeleteFragebogenErgebnisMutation
>;
